<template>
	<div class="app-main__inner">
		<div class="container">

			<div class="row justify-content-end mt-4">
				<div class="col-12 flex flex-col md:flex-row md:justify-between">
					<h2 class="text-xl font-medium text-gray-900">{{ $t('companies') }}</h2>
					<div>
					</div>
					<div class="flex md:justify-end">
						<div class="form-group">
							<div class="input-icons">
								<i class="iconly-brokenSearch"></i>
								<input type="text" class="text-sm h-8 form-control custom-search"
									   v-bind:placeholder="$t('search')" v-model="searchName"
									   v-on:input="searchCompany">
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="main-card mb-3 bg-white">
				<div class="card-body pb-5">
					<div class="table-responsive" v-loading="vLoadingCom">
						<table style="width: 100%;" id="exampl" class="table table-hover table-striped table-bordered">
							<thead>
							<tr>
								<th>
									<div class="d-flex justify-content-between text-xs">{{ $t('companyName') }}<span><i
										class="lnr-arrow-up cursor-pointer"></i><i
										class="lnr-arrow-down cursor-pointer"></i></span></div>
								</th>
								<th>
									<div class="d-flex justify-content-between text-xs">{{
											$t('companyNumber')
										}}<span><i class="lnr-arrow-u cursor-pointer"></i><i
											class="lnr-arrow-dow cursor-pointer"></i></span></div>
								</th>
								<th class="text-xs">Actions</th>
							</tr>
							</thead>
							<tbody>
							<tr v-for="(user, i) in GET_COMPANIES.results" :key="i">
								<td class="text-xs font-light">
									<el-tooltip :content="$t('enterCompanyInfo')" placement="top">
										<span class="cursor-pointer" @click="getCompanyUserID(user.id)">{{
												user.company.name | capitalizeNames
											}}</span>
									</el-tooltip>
								</td>
								<td class="text-xs font-light">{{ user.company.company_id || null }}</td>
								<td class="flex space-x-4">
									<el-tooltip :content="$t('enterCompanyInfo')" placement="top">
										<span @click="getCompanyUserID(user.id)"
											  class="text-base cursor-pointer iconly-brokenEdit icon-color"></span>
									</el-tooltip>
									<!--                                <el-tooltip :content="$t('noteHovering')" placement="top">-->
									<!--                                    <span @click="checkCompanyNote(user.id, 'Company')" class="text-base cursor-pointer iconly-brokenPaper-Plus icon-color"></span>-->
									<!--                                </el-tooltip>-->
									<!--                                <el-tooltip :content="$t('deleteHovering')" placement="top">-->
									<!--                                    <span @click="disconnectCompany(user.id)"   class="text-base cursor-pointer iconly-brokenDelete icon-color"></span>-->
									<!--                                </el-tooltip>-->
								</td>
							</tr>
							<!-- <tr v-if="usersCompany.length < 1" class="odd text-center"><td colspan="5" class="dataTables_empty text-xs" valign="top">{{ $t('noDataTable') }}</td></tr> -->
							</tbody>
						</table>
					</div>
					<div class="float-right mb-5" v-if="GET_COMPANIES.results">
						<paginate
							:page-count="total_pages"
							:click-handler="changeCompanyPageNumber"
							prev-text="<"
							next-text=">"
							:container-class="'pagination'">
						</paginate>
					</div>
					<!-- <board ref="board" :board="activeBoard" v-show="activeBoard" /> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import Paginate from 'vuejs-paginate'
import {Dialog} from 'element-ui'
import debounce from 'lodash/debounce'

Vue.use(Dialog)

export default {
	name: 'Dashboard',
	components: {
		paginate: Paginate,
		// Dialog
	},
	data: function () {
		return {
			vLoadingCom: false,
			searchName: '',
			total_pages: 1
		}
	},
	computed: {
		...mapGetters({
			USER_TOKEN: 'auth/USER_TOKEN',
			USER_REFRESH: 'auth/USER_REFRESH',
			GET_USER_COMPANY_ID: 'customer/GET_USER_COMPANY_ID',
			GET_COMPANIES: 'colleague/GET_COLLEAGUE_COMPANY',
		})
	},
	mounted() {
		this.getConnectCompany('')
	},
	methods: {
		getUsersCompany() {
			this.$store.dispatch('accountant/getUsersCompany')
				.then(_ => {
				}).catch(err => {
				// console.log(err.response)
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getUsersCompany()
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								// window.location.reload()
							})
					}
				}
			})
		},
		getConnectCompany(query = ``) {
			this.vLoadingCom = true
			let params = '?limit=20'
			if (query) {
				params += query
			}
			this.$store.dispatch('colleague/getColleagueConnectCompany', {URL: this.$services.endpoints.ALL_USER_COMPANIES + params}).then(res => {
				this.vLoadingCom = false
				if (res.data.count > 20) {
					this.total_pages = Math.ceil(res.data.count / 20)
				} else {
					this.total_pages = 1
				}
			}).catch(_ => {
				this.vLoadingCom = false
			})
		},
		changeCompanyPageNumber(num) {
			this.vLoadingCom = true
			const url = `${this.$services.endpoints.ALL_USER_COMPANIES} + '?limit=20&page=${num}`
			this.$store.dispatch('colleague/getColleagueConnectCompany', {URL: url})
				.then(res => {
					this.vLoadingCom = false
					if (res.data.count > 20) {
						this.total_pages = Math.ceil(res.data.count / 20)
					} else {
						this.total_pages = 1
					}
				}).catch(err => {
				this.vLoadingCom = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getConnectCompany()
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		searchCompany: debounce(function () {
			let query = ''
			if (this.searchName) {
				query += `&search=${this.searchName}`
			}
			this.getConnectCompany(query)
		}, 500),
		getCompanyUserID(userID) {
			this.$store.commit('customer/SET_USER_COMPANY_ID', userID)
			setTimeout(() => {
				window.open('/automatisera', '_blank')
			}, 1000)
		},
	}
}
</script>

<style scoped>
._company_text {
	font-size: 19px !important;
	padding-top: 16px;
	padding-left: 13px;
	font-weight: 600;
}

.padding-16 {
	padding: 16px;
}

@media only screen and (max-width: 600px) {
	.sm-input-search {
		width: 78% !important;
		float: right;
	}

	._company_text[data-v-0439e3ca] {
		font-size: 19px !important;
		padding-top: 16px !important;
		padding-left: 1px !important;
		font-weight: 600;
	}
}

.input-icons i {
	position: absolute;
	margin: 7px;
	padding-top: 5px;
}

.input-icons {
	width: 100%;
	margin-bottom: 10px;
}

.custom-search {
	padding-left: 1.5rem !important;
}
</style>
